<template>
<button>
    <router-link :to="{ name: 'inputsOutputs', params: {id: '121DS-A112-XDA', settlementFolio: 'S112WLLAXML'} }">{{ 'Back' }}</router-link>
</button>
<ModuleMaster 
:requireSearchField="true"
:requireAddButton="true"
:requirePaginator="true"
:labels="labels"
:numItemsPaginator="totalRecords"
:itemsPerPage="5"
:selectedPage="selectedPage"
:fnCallbackChangePage="handleChangePage"
:fnCallbackCrudAction="handleCrudAction"
>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="true"
        >
            <CrudActions :itemID="row.ID" :callbackCrudAction="handleCrudAction"/>
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalFormEditMovementDetail" ref="btnForm"></button>
<EditFormMovementDetail :mode="modeEditForm" :title="titleEditFormAction" :fnCallBackPersistenceNotifier="handleItemSaved" :currentItem="currentDetail"/>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import CrudActions from "../../components/c-module-master/crud-actions.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../common/formaterToDatatable";
import EditFormMovementDetail from "./movement-detail-edit-form.vue";

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        EditFormMovementDetail
    },
    setup() {
        //VARIABLES Y CONSTANTES
        let records = ref([]);
        let modeForm = ref("N");
        let titleEditForm = ref("Nuevo Artículo");
        let currentPage = ref(1);
        const cantRecordsPage = 5;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const currentItem = ref(null);

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Artículos",
            titleNewCrudAction: "Nuevo Artículo",
        });

        const arrayTitleActions = [
            { action: "N", title: "Nuevo Artículo" },
            { action: "M", title: "Modificar Artículo" },
            { action: "V", title: "Visualizar Artículo" }
        ];

        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Artículo", Type: "text", VisibleInGrid: true },
            { PropName: "quantity", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "text", VisibleInGrid: true },
            { PropName: "discountRate", HeadLabel: "Descuento", Type: "text", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "text", VisibleInGrid: true }
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        })

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable);
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const currentDetail = computed(() => {
            return currentItem.value;
        });


        //FUNCIONES
        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const updateRowItem = async (item) => {
            await getItemById(item.Id);
            let itemToUpd = records.value.find(i => i.id == item.Id);
            if(itemToUpd && currentItem.value) {
                itemToUpd.price = 4567;// = currentItem.value.movementType;
                // itemToUpd.wherehouse = currentItem.value.wherehouse;
                // itemToUpd.note = currentItem.value.note;
            }
        }

        const handleChangePage = async(page) => {
            currentPage.value = page;
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;          
            if(["M", "V", "E"].some(i => i === action)) {
                await getItemById(itemID);
            }

            if(["N", "M", "V"].some(i => i === action) && btnForm.value) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
               btnForm.value.click();
            } else {
                changeStatusItem(itemID, !currentItem.value.isActive);
            }
        }

        const getPagedItems = async() => {
            await ApiService.get(`movements/0714c22f-0ac1-4f97-b6e0-403ea3ea77e6/details/GetPagedList/currentPage/${currentPage.value}/totalRecords/${cantRecordsPage}`).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            });
        }

        const getItemById = async(id) => {
            await ApiService.get(`movements/0714c22f-0ac1-4f97-b6e0-403ea3ea77e6/details/${id}`).then(res => {
                currentItem.value = res.data;
                // currentItem.value.invoiceFolio = "NF0001545";
                // currentItem.value.note = "Salida de almacén xxxxx";
            });
        }

        const changeStatusItem = async(id, activate) => {
            let url = `movements/${currentItem.value.id}/${ activate ? 'activate' : 'deactivate' }`;
            await ApiService.put(url).then(res => {
                let itemToUpd = records.value.find(i => i.id == id);
                if(itemToUpd) {
                    itemToUpd.isActive = activate;
                }
            });
        }

        const handleItemSaved = async (item) => {
            switch(modeForm.value) {
                case "N":
                    await goToPage(1);
                    break;
                case "M":
                    await updateRowItem(item)
                    break;
                case "E":
                    break;
            }
        }


        return {
            //variables y constantes
            labels,
            btnForm,

            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            selectedPage,
            totalRecords,
            modeEditForm,
            currentDetail,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleItemSaved
        }
    },
})
</script>

<template>
<ModalForm
    id="modalFormEditMovementDetail"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>

    <RowForm>
        <label class="required fs-6 fw-bold mb-2">Artículo</label>
        <Field  name="ProductId">
            <SelectOption 
                class="itemControl"
                name="ProductId"
                :options="products"
                :disabled="readOnly"
                :fullWidth="true"
                @changeSelectValue="onChangeProduct"
            />
        </Field>
    </RowForm>
    <RowForm class="paddingTop" :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Cantidad</label>
            <Field  name="Quantity" v-slot="{field, errorMessage}" @change="onChangeQuantity">
                <el-form-item :error="errorMessage">
                    <input
                        v-bind="field"
                        class="form-control itemControl"
                        type="text" 
                        placeholder="Cantidad"
                        :validate-event="false"
                        :disabled="onlyRead"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">Precio</label>
            <Field  name="Price" v-slot="{value, field, errorMessage}" @change="onChangePrice">
                <el-form-item :error="errorMessage">
                    <input
                        v-bind="field"
                        class="form-control itemControl"
                        type="text" 
                        placeholder="Precio"
                        :validate-event="false"
                        :disabled="onlyRead"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>        
    </RowForm>
    <RowForm class="paddingTop" :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Descuento</label>
            <Field  name="DiscountRate" v-slot="{value, field, errorMessage}" @change="onChangeDiscountRate">
                <el-form-item :error="errorMessage">
                    <input
                        v-bind="field"
                        class="form-control itemControl"
                        type="text" 
                        placeholder="Descuento"
                        :validate-event="false"
                        :disabled="onlyRead"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="fs-6 fw-bold mb-2">Total</label>
            <Field  name="Total" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <input
                        v-bind="field"
                        class="form-control itemControl"
                        type="text" 
                        placeholder="Total"
                        :validate-event="false"
                        :disabled="true"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>        
    </RowForm>
</ModalForm>
</template>

<script>
import { computed, defineComponent, ref, toRefs } from 'vue';
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import { Field, useField } from "vee-validate";
import * as Validations from "yup";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
    components: {
        Field, 
        ModalForm,
        RowForm,
        SelectOption
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Movimiento"
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
        currentItem:{
            type: Object,
            required: false,
            default: () => null
        }
    },
    setup(props) {
        //Variables y constantes
        const { mode, titleModal, currentItem } = toRefs(props);
        const modalForm = ref(null);
        
        const validationSchema = Validations.object().shape({
            ProductId: Validations.string().required().label("ProductId"),
            Quantity: Validations.number().typeError("Sólo se admiten valores numéricos.").required().label("Quantity"),
            Price: Validations.number().typeError("Sólo se admiten valores numéricos.").required().label("Price"),
            DiscountRate: Validations.number().typeError("Sólo se admiten valores numéricos.").required().label("DiscountRate"),
            Total: Validations.number().required().typeError("Sólo se admiten valores numéricos.").label("Total")
        });

        const quantity = ref(null);
        const price = ref(null);
        const discountRate = ref(null);
        const productId = ref(null);

        const products = [{ key: "e2b74956-b631-410d-851f-5630a9efac74", text: "Producto 1"}, { key: "1701ded1-f0c4-4c0b-8225-ec5509272994", text: "Producto 2"}, { key: "8c241abb-f575-4d65-8e58-b0c90ee2b2fb", text: "Producto 3"}]

        //Propiedades computadas
        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const titleForm = computed(() => {
            return titleModal;
        });

        const initialValues = computed(() => {
            let currentData = {};
            if(mode.value === "N"){
                let subTotal = price.value * quantity.value;
                let discount = subTotal * (discountRate.value/100);
                let total = subTotal - discount;
                currentData = { Total: total, Quantity: quantity.value, Price: price.value, DiscountRate: discountRate.value, ProductId: productId.value }
            } else {
                if(currentItem.value) {
                    currentData = { 
                        Total: currentItem.value.total, 
                        Quantity: currentItem.value.quantity, 
                        Price: currentItem.value.price, 
                        DiscountRate: currentItem.value.discountRate,
                        ProductId: currentItem.value.productId
                    }
                }
            }
            return currentData;
        });

        //FUNCIONES
        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                await saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            let url = `details`;
            item.UserId = "5ef102f0-24ed-443f-9480-788913f2036c";
            await ApiService.post(url, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `movements/${currentItem.value.id}`;
            item.UserId = "5ef102f0-24ed-443f-9480-788913f2036c";
            item.Id = currentItem.value.id;
            await ApiService.put(url, item).then(res => {
                if(res.data.response.statusCode == 201) {
                    modalForm.value.closeModal();
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            });
        }

        const onChangeProduct = (id) => {
            productId.value = id;
        }

        const onChangeQuantity = (field) => {
            if(!isNaN(field.currentTarget._value)) {
                quantity.value = Number(field.currentTarget._value);
            }
        }

        const onChangePrice = (field) => {
            if(!isNaN(field.currentTarget._value)) {
                price.value = Number(field.currentTarget._value);
            }
        }

        const onChangeDiscountRate = (field) => {
            if(!isNaN(field.currentTarget._value)) {
                discountRate.value = Number(field.currentTarget._value);
            }
        }

        return {
            //Variables y constantes
            modalForm,
            validationSchema,
            products,

            //Propiedades computadas
            readOnly,
            titleForm,
            initialValues,

            //Funciones
            handleCrudAction,
            onChangeQuantity,
            onChangePrice,
            onChangeDiscountRate,
            onChangeProduct
        }


    },
})
</script>

<style lang="scss">
    .itemControl {
        min-width: 250px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>
